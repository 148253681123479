<template>
	<div style="border: 1px solid #ccc;">
		<Toolbar style="border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig" :mode="mode" />
		<!-- <Editor
            style="height: 500px; overflow-y: hidden;"
            v-model="html"
            :defaultConfig="editorConfig"
            :mode="mode"
            @onCreated="onCreated"
        /> -->
		<Editor style="height: 500px; overflow-y: hidden;" :defaultConfig="editorConfig" v-model="html"
			@onCreated="onCreated" @onChange="onChange" @onDestroyed="onDestroyed" @onMaxLength="onMaxLength"
			@onFocus="onFocus" @onBlur="onBlur" @customAlert="customAlert" @customPaste="customPaste" />
	</div>
</template>
<script>
	import Vue from 'vue'
	import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
	import config from '../config/config'

	export default Vue.extend({
		components: { Editor, Toolbar },
		data() {
			return {
				editor: null,
				html: '',
				toolbarConfig: {
					toolbarKeys: [
						"bold",
						"underline",
						"italic",
						"through",
						"sub",
						"sup",
						"clearStyle",
						"color",
						"bgColor",
						"fontSize",
						"fontFamily",
						"indent",
						"delIndent",
						"justifyLeft",
						"justifyRight",
						"justifyCenter",
						"justifyJustify",
						"lineHeight",
						"divider",
						"emotion",
						"blockquote",
						"headerSelect",
						"redo",
						"undo",
						"enter",
						"bulletedList",
						"numberedList",
						"insertTable",
						"deleteTable",
						"insertTableRow",
						"deleteTableRow",
						"insertTableCol",
						"deleteTableCol",
						"tableHeader",
						"tableFullWidth",
						"uploadImage",
					],
					excludeKeys: [
						"header1",
						"header2",
						"header3",
						"header4",
						"header5",
						"code",
						"viewImageLink",
						"imageWidth30",
						"imageWidth50",
						"imageWidth100",
						"insertImage",
						"deleteImage",
						"editImage",
						"insertLink",
						"editLink",
						"unLink",
						"viewLink",
						"codeBlock",
						"todo",
						"fullScreen",
						"insertVideo",
						"uploadVideo",
						"editVideoSize",
						"codeSelectLang"
					]
				},
				editorConfig: {
					placeholder: '请输入内容...',
					MENU_CONF: {
						'uploadImage': {
							server: config.requestUrl + 'search/fileUpload?type=1',
							timeout: 5 * 1000, // 5s

							fieldName: 'file',
							// meta: { token: 'xxx', a: 100 },
							metaWithUrl: true, // join params to url
							headers: { Accept: 'application/json, text/javascript, */*' },

							maxFileSize: 10 * 1024 * 1024, // 10M

							base64LimitSize: 5 * 1024, // insert base64 format, if file's size less than 5kb

							onBeforeUpload(file) {
								console.log('onBeforeUpload', file)

								return file // will upload this file
								// return false // prevent upload
							},
							onProgress(progress) {
								console.log('onProgress', progress)
							},
							onSuccess(file, res) {
								console.log('onSuccess', file, res)
							},
							onFailed(file, res) {
								alert(res.message)
								console.log('onFailed', file, res)
							},
							onError(file, err, res) {
								alert(err.message)
								console.error('onError', file, err, res)
							},
							customInsert(res, insertFn) { // JS 语法
								console.log('customInsert', res, insertFn)
								// res 即服务端的返回结果

								// 从 res 中找到 url poster ，然后插入视频
								insertFn(res.data.src)
							},
						}
					},
				},
				mode: 'default', // or 'simple'
			}
		},
		methods: {
			onCreated(editor) {
				this.editor = Object.seal(editor)
				console.log('onCreated', editor)
			},
			onChange(editor) { console.log('onChange', editor.children) },
			onDestroyed(editor) { console.log('onDestroyed', editor) },
			onMaxLength(editor) { console.log('onMaxLength', editor) },
			onFocus(editor) { console.log('onFocus', editor) },
			onBlur(editor) { console.log('onBlur', editor) },
			customPaste(editor, event, callback) {
				console.log('ClipboardEvent 粘贴事件对象', event)
				// const html = event.clipboardData.getData('text/html') // 获取粘贴的 html
				// const text = event.clipboardData.getData('text/plain') // 获取粘贴的纯文本
				// const rtf = event.clipboardData.getData('text/rtf') // 获取 rtf 数据（如从 word wsp 复制粘贴）

				// 自定义插入内容
				editor.insertText('xxx')

				// 返回 false ，阻止默认粘贴行为
				event.preventDefault()
				callback(false) // 返回值（注意，vue 事件的返回值，不能用 return）

				// 返回 true ，继续默认的粘贴行为
				// callback(true)
			},
			customAlert(e) {
				console.log(e)
			}
		},
		mounted() {
			// 模拟 ajax 请求，异步渲染编辑器
			setTimeout(() => {
				console.log(this.html)
				console.log(this.editor.getAllMenuKeys())
			}, 5000)
		},
		beforeDestroy() {
			const editor = this.editor
			if (editor == null) return
			editor.destroy() // 组件销毁时，及时销毁编辑器
		}
	})

</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>

